import React from 'react'
import Layout from '../components/Layout'
import Loadable from 'react-loadable'
import PageHeader from '../components/PageHeader'
import { graphql } from 'gatsby'

const LoadableCallendar = Loadable({
  loader: () => import('../components/ScheduleCalendar'),
  loading() {
    return <div>Loading...</div>
  },
})

const SchedulePage = ({ data }) => {
  return (
    <Layout>
      <main style={{ backgroundColor: 'white' }} className="DefaultPage">
        <PageHeader
          title={data.settingsYaml.title}
          subtitle={data.settingsYaml.subtitle}
          backgroundImage={data.settingsYaml.featuredImage}
        />
        <section className="section">
          <div className="container">
            <LoadableCallendar />
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default SchedulePage

export const pageQuery = graphql`
  query KalendarzPage {
    settingsYaml {
      featuredImage
      subtitle
      title
    }
  }
`
